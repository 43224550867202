/**
 * Represents whole window application.
**/
.App {
    width: 100vw;
    height: 100vh;
    background-image: url('./background.png');
    background-size: contain;
    background-repeat: repeat;
    overflow: scroll;
}
