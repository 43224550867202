
$primary-color: #71D2F1;
$secondary-color: #e8e8e8;

$emphasize-color: blue;
$highlight-color: orange;

$white-color: white;
$black-color: black;

$bg-color: #061C2C;
