
app-screen {
    width: 100vw;
    max-width: 480px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}




// TODO: Refactor
$default-game-width: 100vw;
$default-sheet-width: 80vw;
$default-detect-width: 40vw;

$max-game-width: 480px;
$max-sheet-width: $max-game-width - 80px;
$max-detect-width: $max-sheet-width - 120px;

.game-width {
    width:     $default-game-width;
    max-width: $max-game-width;
}

.game-square {
    @extend .game-width;
    height:     $default-game-width;
    max-height: $max-game-width;
}

.sheet-width {
    width:     $default-sheet-width;
    max-width: $max-sheet-width;
}

.sheet-square {
    @extend .sheet-width;
    height:     $default-sheet-width;
    max-height: $max-sheet-width;
}

.detect-square {
    width: $default-detect-width;
    max-width: $max-detect-width;
    height: $default-detect-width;
    max-height: $max-detect-width;
}