@import "../../../styles/colors.scss";

.Modal_Content_Wrapper {
    display: flex;
    flex-direction: column;
    .Content_Icon {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    .Content_Message {
        display: flex;
        justify-content: center;
        margin: 20px 0;
        .Found_Value {
            font-weight: bold;
        }
        &.Lines {
            flex-direction: column;
            p {
                margin: 0;
                text-align: center;
            }
            span {
                text-align: center;
                font-size: 0.4em;
            }
        }
    }
    .Content_Actions {
        display: flex;
        padding: 20px 0;
        >button {
            border: none;
            display: block;
            background-color: $primary-color;
            flex: 1;
            padding: 20px;
            font-weight: bold;
        }
    }
}