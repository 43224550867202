@import "../../styles/sizing.scss";
@import "../../styles/colors.scss";
@import "../../styles/overlay.scss";

div.Video_Stream_Container {
    z-index: $z-index-video-scan + 1;
    top: 0;  bottom: 0;
    left: 0; right: 0;
    @extend .game-width;
    height: 100vh;
    background-color: $black-color;
    display: flex;
    flex-direction: column;

    .Video_Stream_Movie {
        @extend .game-square;
        position: relative;
        .Video_Stream_Detect_Area {
        z-index: $z-index-video-scan + 2;
            position: absolute;
            @extend .game-square;
            display: flex;
            align-items: center;
            justify-content: center;
            .Video_Stream_Detect_Area_Line {
                @extend .detect-square;
                box-sizing: border-box;
                border: dashed 8px rgba(255, 255, 255, 0.4);
            }
        }
        video {
            position: absolute;
        }
    }

    .Video_Stream_Navigation {
        min-height: 80px;
        display: flex;
        flex-direction: row;
        >div {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white-color;
        }
        .nav-left {
            flex: 1;
            min-width: 40px;
            >.nav-item-img {
                margin-right: 8px;
            }
        }
        .nav-title {
            flex-grow: 1;
        }
        .nav-right {
            flex: 1;
            min-width: 40px;
        }
    }
}
